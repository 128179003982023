@tailwind base;
@tailwind components;
@tailwind utilities;
body {
    margin: 0;
    font-family: 'Nunito Sans', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #222222;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.25;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.no-border {
    border: 0;
}

div[aria-disabled=true] {
    background: #F7F7FA;
}

input[readonly] + span > span {
    color: #989898 !important;
}

.rs-stack {
    min-height: 22px !important;
}

/* width */
::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #ECECEC;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #006D85;
}

.title-color {
    color: #fff;
}

.checkbox-wrapper {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
}

.checkbox-wrapper input[type="checkbox"] {
    -webkit-appearance: none;
    appearance: none;
    width: 20px;
    height: 20px;
    border-radius: 6px;
    margin-right: 6px;
    border: 1px solid #D3D3D3;
    outline: none;
    cursor: pointer;
}

input.checked {
    background-color: #006D85;
    border-color: #006D85;
    position: relative;
}

input.checked::before {
    content: "\2713";
    font-size: 13px;
    font-weight: 900;
    color: #fff;
    position: absolute;
    right: 4px;
    top: 1px;
}

.recharts-legend-item {
    display: inline-flex !important;
    align-items: center !important;
}

.recharts-default-legend {
    margin-bottom: 10px !important;
}

.form-tree {
    width: 100% !important;
}

.react-time-picker__wrapper {
    border-color: rgba(0, 0, 0, 0.1) !important;
    padding: 6px !important;
    border-radius: 6px !important;
}

.tree-error .rs-picker-default .rs-picker-toggle {
    border-color: red !important;
}

.rs-picker-has-value .rs-btn .rs-picker-toggle-value, .rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {
    color: #000 !important;
}

.mb-0 .form-group {
    margin-bottom: 0 !important;
}

iframe#webpack-dev-server-client-overlay {
    display: none !important
}

.react-dropdown-tree-select .dropdown .dropdown-content {
    padding: 10px 15px !important;
    height: 40vh !important;
    width: 100%;
    overflow-y: scroll;
}

.toggle.collapsed:after, .toggle.expanded:after {
    font-size: 22px;
}

.loading .toggle.collapsed:after {
    background-image: url("../../assets/icons/spinner.gif");
    background-size: cover;
    display: inline-block;
    width: 20px;
    height: 16px;
    content: "";
}

.rs-picker-toggle-wrapper {
    display: block !important;
}

.rs-picker-default .rs-picker-toggle.rs-btn-lg {
    padding-top: 12px !important;
    padding-bottom: 12px !important;
}

.rs-picker-default .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-indicator .rs-picker-caret-icon, .rs-picker-default .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-indicator .rs-picker-clean {
    top: 13px !important;
}

.rs-picker-has-value.rs-picker-cleanable .rs-picker-toggle.rs-btn-lg, .rs-picker-toggle-wrapper .rs-picker-toggle.rs-btn-lg {
    padding-right: 20px !important;
}

.rs-picker-toggle {
    border-radius: 8px !important;
}

.rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle-active, .rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:focus, .rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:hover {
    border-color: #006D85 !important;
}

.rs-picker-popup {
    z-index: 1000;
}

.rs-picker-popup {
    max-width: 500px !important;
}

.rs-loader {
    margin-top: 6px !important;
}

.rs-tree-node-label {
    padding: 0 !important;
    display: block !important;
    width: 100% !important;
}

.react-datepicker-wrapper {
    display: block !important;
}

@layer components {

    .form-group {
        @apply mb-4;
    }

    .form-label {
        @apply mb-1.5 block text-secondary-300 font-semibold;
    }

    .form-error {
        @apply text-[#EB5757] text-xs block text-right mt-1;
    }

    .form-input {
        @apply block p-3 w-full border border-secondary-100 rounded-lg focus:border-primary focus:outline-none placeholder-secondary-200 h-12;
    }


    .react-dropdown-tree-select .dropdown .dropdown-trigger {
        @apply block px-3 py-1.5  border border-secondary-100 rounded-lg focus:border-primary focus:outline-none placeholder-secondary-200 h-12 overflow-hidden w-full;
    }

    .react-dropdown-tree-select .dropdown {
        @apply w-full;
    }

    .menu__item {
        @apply hover:text-primary transition ml-8 text-secondary-300 font-semibold relative
    }


    .menu__item_link {
        @apply py-4 inline-block relative before:w-full before:h-2  before:absolute before:-top-3.5 before:rounded-b-xl
    }

    .menu__item ul li > a.active {
        @apply text-primary bg-[#D9D9D9]
    }

    .menu__item_link.active {
        @apply text-primary  before:bg-primary
    }

    .tab__item_link {
        @apply text-[#4A4A4A] font-bold pt-2 pb-4 px-4  mr-6 relative before:w-full before:h-1.5  before:absolute before:bottom-0 before:rounded-t-xl before:left-1/2 before:-translate-x-1/2
    }

    .tab__item_link.active {
        @apply text-primary  before:bg-primary
    }

    .table {
        @apply w-full
    }

    .table.bordered .td, .table.bordered .th {
        @apply border border-2
    }


    .thead .tr {
        @apply border-b border-b-['#EDEDED'] sticky top-0 bg-white z-0
    }

    .th {
        @apply py-5 px-4   uppercase text-[#222] text-center whitespace-nowrap   max-w-[320px] text-sm font-bold;
    }


    .th:first-child, .th:nth-child(2) {
        @apply text-left
    }


    .td {
        @apply py-3 px-4 text-center  max-w-[500px]  text-[#222]
    }

    .td:first-child, .td:nth-child(2) {
        @apply text-left
    }


    .tbody .tr {
        @apply bg-white  hover:bg-[#E6F1F3] transition cursor-pointer   border-b border-b-['#EDEDED'] align-middle
    }

    .tbody .tr.no-border {
        @apply border-b-transparent
    }

    .pagination {
        @apply flex justify-end;
    }

    .pagination li {
        @apply text-secondary-300 p-1 w-10 h-10 text-center rounded-lg  flex justify-center items-center border border-transparent font-semibold;
    }

    .pagination li.selected {
        @apply border-primary text-primary;
    }
}
